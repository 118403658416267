import React, { useEffect, useState } from "react";
import useStyles from "../components/auth/user-jss";
import Card from "@views/components/card/card";
import taj from "@assets/images/taj.jpg";
import skyscraper from "@assets/images/skyscraper.jpg";
import oiicon from "@assets/images/airplane-around-earth.png";
import wiicon from "@assets/images/india.png";
import tnlogo from "@assets/images/tn_logo.png";
import { navigate } from "@helpers/navigator";

function Home() {
  const { classes } = useStyles();

  const handle_outside_india = () => {
    navigate("/login/" + "outside-india");
  };

  const handle_within_india = () => {
    navigate("/login/" + "within-india");
  };

  return (
    <div className={classes.rootFull}>
      <div className="home">
        <div className="home-overlay"></div>
        <div className="home-content">
          <div className="home-img-content">
            <img className="home-img" src={tnlogo} />
          </div>
          <div className="home-title">TN ePass</div>
          <div className="text-center mb-5">
            நீங்கள் எங்கே இருந்து வருகிறீர்கள் / Where are you Coming From
          </div>

          <div className="row grid-container">
            <Card
              imageUrl={skyscraper}
              text="இந்தியாவுக்கு வெளியில் இருந்து / Outside India"
              color="#604432d6"
              icon={oiicon}
              onClick={handle_outside_india}
            />
            <Card
              imageUrl={taj}
              text="இந்தியாவுக்கு உள்ளே / Within India"
              color="#3c6e36d6"
              icon={wiicon}
              onClick={handle_within_india}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
